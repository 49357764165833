import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import farmDetailsHeader from "../../images/FarmDetails.jpg"
import {default as styles} from "./Styles.jsx"
import farmPicPlaceholder from "../../images/farmPicPlaceholder.png"

export default function PdfFarmDetails(farmDetails){

  const formatDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
  
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    let suffix = 'th';
    if (day === '01' || day === '21' || day === '31') {
      suffix = 'st';
    } else if (day === '02' || day === '22') {
      suffix = 'nd';
    } else if (day === '03' || day === '23') {
      suffix = 'rd';
    }
  
    return `${parseInt(day, 10)}${suffix} ${months[parseInt(month, 10) - 1]} ${year}`;
  };

    return(
      <Page>
        <div style = {styles.headerImageBox} >
          <Image  
            src = {farmDetailsHeader}/>
          <div style={{position: 'absolute'}}>
            <Text style={styles.title}>Farm Details</Text>
          </div>
        </div>
        <div>
          <View style = {styles.farm_details_box}>
          
          <div style = {styles.farm_details_first_column}>
            <Text style={styles.contents}>Supplier number </Text>
            <Text style={styles.contents}>Farm owner</Text>
            <Text style={styles.contents}>Plan owner</Text>
            <Text style={styles.contents}>Farm address</Text>
            <Text style={styles.contents}>Location</Text>
            <Text style={[styles.contents,{paddingTop: "70%"} ]}>Plan last edited</Text>
          </div>
          
          <div style = {styles.farm_details_second_column}>
            <Text style={styles.farm_details}>{farmDetails.supplier_no}</Text>
            <Text style={styles.farm_details}>{farmDetails.farm_owner} </Text>
            <Text style={styles.farm_details}>{farmDetails.plan_owner} </Text>
            <Text style={styles.farm_details1}>{farmDetails.farmAddress} </Text>
            <div style={{marginTop: 25, width: "50%", height:"35%" }}>
              <Image
                id='imgPreview'
                src={farmDetails.farmPic || farmPicPlaceholder}
                alt = "Farm Location"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: 'fill'
                }}
              />
            </div>
            <Text style={[styles.farm_details]}>{formatDate(farmDetails.date_edited)}</Text>
          </div> 
          </View>
        </div>
        <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
        <Text style={styles.pageNumber} render={({ pageNumber }) => (
         `${pageNumber}`
        )} fixed />
      </Page>
    )
}
