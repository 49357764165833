import { TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import { AppRunTimeContext } from '../Context/index';
import { useContext, useEffect } from 'react';
import Map from "./Map";
import axios from 'axios';
import acquireAccessToken from "../accessToken"
import { useMsal } from "@azure/msal-react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import * as  htmlToImage from 'html-to-image';
import CircularProgress from '@mui/material/CircularProgress';
import ProgressBar from "./ProgressBar";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';

const farmRegion = [
    {
        value: 'North',
        label: 'North',
    },
    {
        value: 'South',
        label: 'South',
    },
    {
        value: 'East',
        label: 'East',
    },
    {
        value: 'West',
        label: 'West',
    }
];



export default function FarmDetails() {
    const { farmDetails, setFarmDetails, payload, setPayload, operation, setOperation, highestRevisionNumber, setHighestRevisionNumber } = useContext(AppRunTimeContext);
    const { instance, accounts } = useMsal();
    const [fetchedData, setFetchedData] = React.useState([]);
    const [fetchedDataSale, setFetchedDataSale] = React.useState([]);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openSalesforceDialog, setSalesforceDialog] = React.useState(false);
    const [openSaveSuccessDialog, setOpenSaveSuccessDialog] = React.useState(false);
    const [openSaveFailedDialog, setOpenSaveFailedDialog] = React.useState(false);
    const [openSavingDialog, setOpenSavingDialog] = React.useState(false);
    const [openSettingAddressDialog, setOpenSettingAddressDialog] = useState(false);
    const [openImageFormatDialog, setOpenImageFormatDialog] = React.useState(false);
    const [openImageSizeDialog, setOpenImageSizeDialog] = React.useState(false);  
    const mapRef = useRef(null);
    //State to handle the ticked/unticked checkboxes
    const [state, setState] = React.useState(null);
  
      // Called when a checkbox is ticked/unticked
   const handleChange = (event) => {
  
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
    
      farmDetails.mapRequired = event.target.checked;
    };

     console.log(fetchedData);

    const farmDetailsRef = useRef();
    useEffect(() => {
        farmDetailsRef.current = farmDetails;
    }, [farmDetails]);

    const navigate = useNavigate();
    
    const handleImageFormatDialogClose = () => {
        setOpenImageFormatDialog(false);
    };
    
    const handleImageSizeDialogClose = () => {
        setOpenImageSizeDialog(false);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleSaveSuccessDialogClose = () => {
        setOpenSaveSuccessDialog(false);
    };

    const handleSaveFailedDialogClose = () => {
        setOpenSaveFailedDialog(false);
    };


    const handleNextClick = () => {
        if (farmDetails.supplier_no && farmDetails.farmRegion && farmDetails.farm_owner && farmDetails.plan_owner && farmDetails.financialYear) {
            // Navigate to the next page
            navigate('/ModulePage');  // Use navigate to go to ModulePage
        } else {
            // Open the dialog
            setOpenDialog(true);
        }
    };


    useEffect(() => {
        const navigationEntries = window.performance.getEntriesByType("navigation");
        if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
            window.location.href = '/'; // Redirect to home page
        }
        // if (!farmDetails.revisionNumber || farmDetails.revisionNumber.trim() === '') {
        //     farmDetails.revisionNumber = '0001';
        // } 

    }, []);
     const currentMonth = new Date().getMonth();
     let currentYear;
     if (currentMonth >= 7) {
          // If the current month is August (7) or later, set the current year
          currentYear = new Date().getFullYear();
        } else {
          // If the current month is earlier than August, subtract 1 from the current year
          currentYear = new Date().getFullYear() - 1;
        }
    const prevYear = currentYear - 1;
 
    const nextYear = currentYear + 1;
    const twonextYear = currentYear + 2;
   

    const financialYear = [
                {
            value: "FY - " + prevYear,
            label: "FY - " + prevYear,
        },
        {
            value: "FY - " + currentYear,
            label: "FY - " + currentYear,
        },
        {
            value: "FY - " + nextYear,
            label: "FY - " + nextYear,
        },
        {
            value: "FY - " + twonextYear,
            label: "FY - " + twonextYear,
        },

    ];

    // Function to get the address from latitude and longitude
    const getAddressFromCoordinates = async (lat, lng) => {

        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

        try {
            const response = await axios.get(url);
            if (response.data.status === 'OK') {
                // Assuming you want the formatted address from the first result
                return response.data.results[0]?.formatted_address ?? "";
            } else {
                console.error('Geocoding API error:', response.data.status);
                return "";
            }
        } catch (error) {
            console.error('Geocoding request failed:', error);
            return "";
        }
    };

        // Helper function to make Axios call
    const makeAxiosCallSalesforce = async (urlSuffix) => {
        try {
            const token = await acquireAccessToken(instance, accounts[0]);
            localStorage.setItem("accessToken", token);
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/getFarmAddress/${urlSuffix}`,  {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Response:', response.data);
            setFetchedDataSale(response.data);
          
            setSalesforceDialog(false);
            console.log(response.data.records.length);
            if(response.data.records.length > 0) {

            
             setFarmDetails(prevDetails => ({
                   ...prevDetails,
                    farmRegion: response.data.records[0]?.Farm_Region_Name__c ?? "",
                    farm_owner: response.data.records[0]?.Farm_Name__c ?? "",
                    plan_owner: response.data.records[0].Primary_On_Farm_Contact__r.Name ?? "",
                    farmAddressLat: response.data.records[0]?.Farm_Latitude__c ?? "",
                    farmAddressLong: response.data.records[0]?.Farm_Longitude__c ?? "",
                	// farmAddress: getAddressFromCoordinates(response.data.records[0]?.Farm_Latitude__c ?? "", response.data.records[0]?.Farm_Longitude__c ?? "")

                }));
                // Trigger map screenshot capture
                 setTimeout(() => {
                     if (mapRef.current) {
                         mapRef.current.downloadScreenshot().then(dataURI => {
                             updateFarmPic(dataURI); // Update the farm picture with the screenshot
                         });
                     }
                 }, 3000); // Adjust delay as needed
 
         }
            else {
                    setFarmDetails(prevDetails => ({
                   ...prevDetails,
                    farmRegion: "",
                    farm_owner: "",
                    plan_owner: "",
                    farmAddressLat: 0,
                    farmAddressLong: 0,
                    farmAddress: ""

             }));

            }
        } catch (error) {
             if (error.response) {
                // The request was made and the server responded with a status code
                // other than 2xx (e.g., 500)
                console.error('Server responded with an error status:', error.response.status);
                console.error('Server response data:', error.response.data);
              } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received from the server:', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error setting up the request:', error.message);
              }
        }
     
    };

    const isAEDT = (date) => {
      const year = date.getFullYear();
      const startDST = new Date(Date.UTC(year, 9, 1)); // October 1st
      const endDST = new Date(Date.UTC(year, 3, 1)); // April 1st

      // Find the first Sunday in October
      startDST.setUTCDate(1 + (7 - startDST.getUTCDay()) % 7);
  
      // Find the first Sunday in April
      endDST.setUTCDate(1 + (7 - endDST.getUTCDay()) % 7);

      return (date >= startDST && date < endDST);
    };


      const getCurrentDateTime = () => {
        const nowUTC = new Date(Date.now());

        const offset = isAEDT(nowUTC) ? 12 : 11; // UTC+13 if Daylight Saving Time, otherwise UTC+12
  
        const aet = new Date(nowUTC.getUTCFullYear(), nowUTC.getUTCMonth(), nowUTC.getUTCDate(),
                             nowUTC.getUTCHours() + offset, nowUTC.getUTCMinutes(), nowUTC.getUTCSeconds());
  
        const year = aet.getFullYear();
        const month = String(aet.getMonth() + 1).padStart(2, '0');
        const day = String(aet.getDate()).padStart(2, '0');
        const hour = String(aet.getHours()).padStart(2, '0');
        const minute = String(aet.getMinutes()).padStart(2, '0');
        const second = String(aet.getSeconds()).padStart(2, '0');
  
        return `${year}${month}${day}${hour}${minute}${second}`;
      };

    const preparePayload = (prevDetails, specificFields = {}) => {
        const { surveyData } = farmDetails;

        // Prepare 'details' object
        const details = {
            farmOwner: farmDetails.farm_owner,
            planOwner: farmDetails.plan_owner,
            farmAddressLat: farmDetails.farmAddressLat, // You can replace these with actual data if available
            farmAddressLong: farmDetails.farmAddressLong,
            financialYear: farmDetails.financialYear,
            currentYear: "2023",
            futureYear: "2028",
            farmAddress: farmDetails.farmAddress
        };
        console.log(farmDetails.financialYear)

        // Prepare surveyData without graphs for Carbon and Nitrogen
        const surveyDataWithoutGraphs = { ...farmDetails.surveyData, details }; // add 'details' here

        if (surveyData.Carbon) {
            const { graph: carbonGraph, ...rest } = surveyData.Carbon;
            surveyDataWithoutGraphs.Carbon = rest;
        }

        if (surveyData.Nitrogen) {
            const { graph: nitrogenGraph, ...rest } = surveyData.Nitrogen;
            surveyDataWithoutGraphs.Nitrogen = rest;
        }

        if (surveyData.Irrigation) {
            const { graph: irrigationGraph, ...rest } = surveyData.Irrigation;
            surveyDataWithoutGraphs.Irrigation = rest;
        }

        if (surveyData.HealthyCalves) {
            const { colostrum, calfPathways, ...rest } = surveyData.HealthyCalves;

            // Remove graph1 and graph2 from colostrum
            const { graph1: colostrumGraph1, graph2: colostrumGraph2, ...colostrumRest } = colostrum;

            // Remove graph1 and graph2 from calfPathways
            const { graph1: calfPathwaysGraph1, graph2: calfPathwaysGraph2, ...calfPathwaysRest } = calfPathways;

            surveyDataWithoutGraphs.HealthyCalves = {
                ...rest,
                colostrum: colostrumRest,
                calfPathways: calfPathwaysRest
            };

            if (surveyData.GeneralFarm) {
                const { farmMap, ...restGeneralFarm } = surveyData.GeneralFarm;
                surveyDataWithoutGraphs.GeneralFarm = restGeneralFarm;
            }
        }

        return {
            ...prevDetails,
            dateEdited: getCurrentDateTime(),
            region: farmDetails.farmRegion,
             financialYear: farmDetails.financialYear,
            farmId: farmDetails.supplier_no,
            status: "DRAFT",
            surveyData: surveyDataWithoutGraphs, // use the modified 'surveyDataWithoutGraphs'
            modules: farmDetails.modules,
            mapRequired: farmDetails.mapRequired,
            ...specificFields
        };
    };




    // Helper function to make Axios call
    const makeAxiosCall = async (urlSuffix, payload) => {
        try {
            const token = await acquireAccessToken(instance, accounts[0]);
            localStorage.setItem("accessToken", token);
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/fep/farm/${urlSuffix}`, payload, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            console.log('Response:', response.data);
            console.log(payload);
            setFetchedData(response.data);
            return response;

        } catch (error) {
            console.error('An error occurred while performing Axios POST:', error);
            return null;  // Return null if there's an error
        }
    };

    function updateFarmPic(dataURI) {
        setFarmDetails(prevDetails => ({
            ...prevDetails,
            farmPic: dataURI,
            hasFarmPicChanged: true
        }));
    }

    function handleSettingAddress() {
        setOpenSettingAddressDialog(true);
        setTimeout(() => {
            setOpenSettingAddressDialog(false);
        }, 3000);
    }



    const handleSave = async () => {
        if (farmDetails.supplier_no && farmDetails.farmRegion && farmDetails.farm_owner && farmDetails.plan_owner && farmDetails.financialYear) {
            const urlSuffix = operation === "modify" ? "insertFarmSurveyData" : "insertFarmSurveyData";
            setOpenSavingDialog(true);
            if (operation !== "modify") {
                farmDetails.farmSurveyId = farmDetails.supplier_no + getCurrentDateTime();
            }
            // Check if highestRevisionNumber is defined and is a string of 4 digits
            let validRevisionNumber = highestRevisionNumber && /^\d{4}$/.test(highestRevisionNumber);
            let newRevisionNumber;

            if (validRevisionNumber) {
                // Increment the revision number if it's valid
                newRevisionNumber = (parseInt(highestRevisionNumber, 10) + 1).toString().padStart(4, '0');
            } else {
                // Default to '0001' if highestRevisionNumber is undefined or invalid
                newRevisionNumber = '0001';
            }


            // Define a helper function to make the image upload call
            const uploadGraph = async (imageName, graphData) => {
                if (imageName.startsWith('farmPic_')) {
                if (!farmDetails.hasFarmPicChanged) {
                    return true
                }
                else {
                    setFarmDetails(prevDetails => ({
                    ...prevDetails,
                    hasFarmPicChanged: false
                    }));
                }
                }
                const imagePayload = {
                "imageName": imageName,
                "imageDetails": graphData
                };
                console.log("Image Payload:", imagePayload);
                return makeAxiosCall("storeImage", imagePayload);
            };


            setPayload(prevDetails => {
                const specificFields = operation === "modify" ? {
                    revisionNumber: newRevisionNumber,
                    id: farmDetails.supplier_no + `_${newRevisionNumber}`,
                    editedBy: accounts[0].name
                } : {
                    creatorName: accounts[0].name,
                    dateCreated: getCurrentDateTime(),
                    id: farmDetails.supplier_no + `_${newRevisionNumber}`,
                    schemaVersionNum: "001",
                    surveyVersionNumber: "1",
                    status: "DRAFT",
                    farmSurveyId: farmDetails.farmSurveyId,
                    revisionNumber: newRevisionNumber,
                    editedBy: accounts[0].name
                };
     
                const updatedPayload = preparePayload(prevDetails, specificFields);

                (async () => {
                    try {
                      const response = await makeAxiosCall(urlSuffix, updatedPayload);
            
                      if (response) {
                        setHighestRevisionNumber(newRevisionNumber);
                        if (operation !== "modify") {
                          farmDetails.farmSurveyId = updatedPayload.farmSurveyId;
                          farmDetails.date_edited = updatedPayload.dateEdited;
                          setOperation('modify');
                        }
                        
                    setTimeout(async () => {  // 4000 milliseconds delay
                        const graphPaths = [
                          { path: "HealthyCalves.colostrum.graph1", namePrefix: "healthyCalvesColostrumGraph1_" },
                          { path: "HealthyCalves.colostrum.graph2", namePrefix: "healthyCalvesColostrumGraph2_" },
                          { path: "HealthyCalves.calfPathways.graph1", namePrefix: "healthyCalvesCalfPathwaysGraph1_" },
                          { path: "HealthyCalves.calfPathways.graph2", namePrefix: "healthyCalvesCalfPathwaysGraph2_" },
                          { path: "Nitrogen.graph", namePrefix: "nitrogenGraph_" },
                          { path: "Carbon.graph", namePrefix: "carbonGraph_" },
                          { path: "Irrigation.graph", namePrefix: "irrigationGraph_" },
                          { path: "farmPic", namePrefix: "farmPic_" },
                          { path: "GeneralFarm.farmMap", namePrefix: "farmMap_" }
                        ];
            
                        const graphPromises = graphPaths.map(graph => {
                          const parts = graph.path.split('.');
                          let graphValue;
            
                          switch (parts.length) {
                            case 1:
                              graphValue = farmDetails[parts[0]];
                              break;
                            case 2:
                              if (farmDetails.surveyData && farmDetails.surveyData[parts[0]]) {
                                graphValue = farmDetails.surveyData[parts[0]][parts[1]];
                              }
                              break;
                            case 3:
                              if (farmDetails.surveyData && farmDetails.surveyData[parts[0]] && farmDetails.surveyData[parts[0]][parts[1]]) {
                                graphValue = farmDetails.surveyData[parts[0]][parts[1]][parts[2]];
                              }
                              break;
                            default:
                              break;
                          }
            
                          if (graphValue && graphValue !== "") {
                            return uploadGraph(graph.namePrefix + farmDetails.farmSurveyId + `_${newRevisionNumber}`, graphValue);
                          }
                          return Promise.resolve(true);
                        });
            
                         const graphResponses = await Promise.all(graphPromises);
            
                        if (graphResponses.every(res => res)) {
                          setOpenSavingDialog(false);
                          setOpenSaveSuccessDialog(true);
                        } else {
                          setOpenSavingDialog(false);
                          setOpenSaveFailedDialog(true);
                        }
                        }, 1000);
                      } else {
                        setOpenSavingDialog(false);
                        setOpenSaveFailedDialog(true);
                      }
                      
                    } catch (error) {
                      console.error("An error occurred during saving:", error);
                      setOpenSavingDialog(false);
                      setOpenSaveFailedDialog(true);
                    }
                  })();

                return updatedPayload;
            });
        } else {
            setOpenDialog(true);
        }
    };


    return (
      <div className="farmDetails-page">
       {console.log(farmDetails)}
       {console.log(payload)}
             <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
        <Grid item xs={3}>
         <div className="progressbar">
                 <ProgressBar currentStep={1}/>
           </div>
           </Grid>
            <Grid item xs={9}>
        <header className="farmDetails-header">
     
            <div className="farmDetails-questions">
             <h1 className="farmDetails-h1">Farm Details</h1>
             <h5>* indicates <b>mandatory fields</b></h5>
                <h4>Enter Details </h4>
                <h5><b>After entering Supplier Number/Farm ID, press Enter key</b></h5>

                <div className="benchmark"></div>

                  <p>

                    <TextField  
                        variant="standard"
                        required
                        fullWidth
                        type="text"
                        id="outlined-required"
                        value={farmDetails.supplier_no} // use value instead of defaultValue
                        label="Supplier Number/Farm ID"
                        InputProps={{ style: { fontSize: 20 } }}
                        InputLabelProps={{ style: { fontSize: 20 } }}
                        onChange={(e) => {

                            setFarmDetails(prevDetails => ({
                                ...prevDetails,
                                supplier_no: e.target.value.trim()
                            }));
                        }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    // Call your function here
                                    makeAxiosCallSalesforce(farmDetails.supplier_no);
                                    setSalesforceDialog(true);

                                 

                                   
                                }
                            }}
                    />
                    </p>
                    <p>
                        <TextField
                            id="select-region"
                            required
                            select
                            label="Select appropriate farm region"
                            value={farmDetails.farmRegion} // use value instead of defaultValue
                            style={{ marginLeft: '-1%', marginTop: '5%' }}
                            sx={{ m: 1, width: '25ch' }}
                            onChange={(e) => {
                                setFarmDetails(prevDetails => ({
                                    ...prevDetails,
                                    farmRegion: e.target.value
                                }));
                            }}
                        >
                            {farmRegion.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </p>




                    <p>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            type="text"
                            id="outlined-required"
                            value={farmDetails.farm_owner} // use value instead of defaultValue
                            label="Farm Name"
                            InputProps={{ style: { fontSize: 20 } }}
                            InputLabelProps={{ style: { fontSize: 20 } }}
                            onChange={(e) => {
                                setFarmDetails(prevDetails => ({
                                    ...prevDetails,
                                    farm_owner: e.target.value
                                }));
                            }}
                        />
                    </p>

                    <p>
                        <TextField
                            variant="standard"
                            required
                            fullWidth
                            type="text"
                            id="outlined-required"
                            value={farmDetails.plan_owner} // use value instead of defaultValue
                            label="Plan Owner"
                            InputProps={{ style: { fontSize: 20 } }}
                            InputLabelProps={{ style: { fontSize: 20 } }}
                            onChange={(e) => {
                                setFarmDetails(prevDetails => ({
                                    ...prevDetails,
                                    plan_owner: e.target.value
                                }));
                            }}
                        />
                    </p>

                      <p>
                        <TextField
                            id="select-year"
                            required
                            select
                            label="Select financial year"
                            value={farmDetails.financialYear} // use value instead of defaultValue
                            style={{ marginLeft: '-1%', marginTop: '5%' }}
                            sx={{ m: 1, width: '25ch' }}
                            onChange={(e) => {
                                setFarmDetails(prevDetails => ({
                                    ...prevDetails,
                                    financialYear: e.target.value
                                }));
                            }}
                        >
                            {financialYear.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </p>


                        {!openSalesforceDialog && (
                          <div style={{ width: "100%" }}>
                            <Map updateFarmPic={updateFarmPic} onSettingAddress={handleSettingAddress} ref={mapRef}/>
                          </div>
                        )}



                    <p><h5>OR</h5></p>


                    <p>
                        <h5> Click on box below to insert map of farm location</h5>
                        <input
                            type="file"
                            id="userFileInput"
                            accept="image/*"  // This line restricts to image file types
                            onChange={(e) => {
                                const reader = new FileReader();
                                let fileType = e.target.files[0];  
                                reader.readAsDataURL(e.target.files[0]);
                                reader.onload = () => {
                                    const base64String = reader.result;

                                    // Set the states with the base64 representation
                                    setFarmDetails(prevFarmDetails => ({
                                        ...prevFarmDetails,
                                        farmPic: base64String,
                                        hasFarmPicChanged: true
                                    }));
                                };                                
                                const fileExtension = fileType.name.split(".").at(-1);
                                const allowedFileTypes = ["jpg", "png"];               

                                if (!allowedFileTypes.includes(fileExtension)) {                  
                                setOpenImageFormatDialog(true);
                                e.target.value = null;
                                console.error('Invalid file type. Please select a JPEG or PNG file.');
                                return false;
                                } 
                                else if (fileType.size > 400 * 1024) {
                                setOpenImageSizeDialog(true);
                                e.target.value = null;
                                console.error('File size exceeds the limit (400 KB).');
                                return false;
                                };
                                reader.onerror = (error) => {
                                    console.error('Error converting image to base64:', error);
                                };
                            }}
                        />

                    </p>
                    <p style={{color:'green',fontSize:'12px', marginTop:'-20px', fontWeight:'500'}}>*Only Jpeg/Png image with a size of 400kb are allowed.</p>
                    <h5 style={{ marginTop: '60px', marginBottom:'5px' }}>Please tick the checkbox below if supplier will receive a large scale whiteboard map</h5>       
                    <FormControlLabel
                    control={
                        <Checkbox 
                        onChange={handleChange}
                        style={{ marginLeft: '0%' }}
                        checked={farmDetails.mapRequired}
                        />
                    }
                    label={<p style={{ fontSize: '1.25rem' }}>Large Scale Whiteboard Map</p>}
                    />                    


                </div>

                <div className="back-next-button">
                    <div className="btn-text-left">
                        <Button
                            id="back"
                            style={{ background: "#002D4E" }}
                            variant="contained"
                            onClick={() => {
                                   let previousUrl = null;
                                   console.log('History:', window.history);

                                  // Iterate through the history stack from the most recent to the oldest entry
                                  for (let i = window.history.length - 2; i >= 0; i--) {
                                    const url = window.history[i];
                                    if (url && url.includes('/Review')) {
                                      previousUrl = url;
                                      break; // Stop looping when we find the first occurrence
                                    }
                                  }

                                  if (previousUrl) {
                                    // Found a previous URL containing "/Review"
                                    window.location.href = previousUrl;
                                  } else {
                                    // Navigate to the home page if no "/Review" URL was found
                                    window.location.href = '/';
                                  }
                            }}>
                            Back
                        </Button>
                    </div>

                    <div className="btn-text-right">
                        <Button
                            id="next"
                            style={{ background: "#002D4E", marginLeft: "-3px" }}
                            variant="contained"
                            onClick={handleNextClick} // Use the handleNextClick function here
                        >
                            Next
                        </Button>
                    </div>

                    <div className="btn-text-left">
                        <Button variant="contained" href="#contained-buttons" className="btn-save" id="save" style={{ backgroundColor: "#002D4E", marginLeft: "-3px" }} sx={{ ml: 1 }} onClick={handleSave}>Save</Button>
                    </div>

                    <div className="btn-text-left">

                    </div>
                    </div>






</header>
            </Grid>
        </Grid>
          </Box>
            {/* Dialog Component */}
            <Dialog
            open={openImageFormatDialog}
            onClose={handleImageFormatDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Type"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Invalid file type. Please select a JPEG or PNG file.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageFormatDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openImageSizeDialog}
            onClose={handleImageSizeDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Image Size"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
              File size exceeds the limit (400 KB).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleImageSizeDialogClose} color="primary" autoFocus>
                OK
              </Button>
            </DialogActions>
          </Dialog>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Incomplete Form"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please fill in all the required fields and address before proceeding/saving.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Save Successful Dialog */}
            <Dialog
                open={openSaveSuccessDialog}
                onClose={handleSaveSuccessDialogClose}
                aria-labelledby="save-success-dialog-title"
                aria-describedby="save-success-dialog-description"
            >
                <DialogTitle id="save-success-dialog-title">{"Save Successful"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="save-success-dialog-description">
                        You have successfully saved the form.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSaveSuccessDialogClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Save Failed Dialog */}
            <Dialog
                open={openSaveFailedDialog}
                onClose={handleSaveFailedDialogClose}
                aria-labelledby="save-failed-dialog-title"
                aria-describedby="save-failed-dialog-description"
            >
                <DialogTitle id="save-failed-dialog-title">{"Save Failed"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="save-failed-dialog-description">
                        An error occurred while saving the form. Please try again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSaveFailedDialogClose} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Saving Dialog */}
            <Dialog
                open={openSavingDialog}
                aria-labelledby="saving-dialog-title"
                aria-describedby="saving-dialog-description"
            >
                <DialogTitle id="saving-dialog-title">{"Saving..."}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                        Please wait while we save your form.
                    </DialogContentText>
                    {/* Centered spinner with space above */}
                    <CircularProgress size={40} style={{ marginTop: '10px' }} />
                </DialogContent>
            </Dialog>

                        {/* Salesforce Dialog */}
            <Dialog
                open={openSalesforceDialog}
                aria-labelledby="saving-dialog-title"
                aria-describedby="saving-dialog-description"
            >
                <DialogTitle id="saving-dialog-title">{"Loading..."}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <DialogContentText id="saving-dialog-description" style={{ textAlign: 'center' }}>
                        Please wait while your farm data is loading.
                    </DialogContentText>
                    {/* Centered spinner with space above */}
                    <CircularProgress size={40} style={{ marginTop: '10px' }} />
                </DialogContent>
            </Dialog>


            {/* Setting Address Dialog */}
            <Dialog
                open={openSettingAddressDialog}
                onClose={null} // Disable closing the dialog by clicking outside or pressing ESC
                aria-labelledby="setting-address-dialog-title"
                aria-describedby="setting-address-dialog-description"
            >
                <DialogTitle id="setting-address-dialog-title">{"Setting address..."}</DialogTitle>
                <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <DialogContentText id="setting-address-dialog-description" style={{ textAlign: 'center' }}>
                        Please wait while we set the address.
                    </DialogContentText>
                    {/* Centered spinner with space above */}
                    <CircularProgress size={40} style={{ marginTop: '10px' }} />
                </DialogContent>
            </Dialog>



        </div>
    );
}  
