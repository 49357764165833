import React  from 'react';
import { Page, Text, Image, View } from '@react-pdf/renderer';
import action from "../../../images/Action.jpg"
import {default as styles} from "../Styles"
import farmMapPlaceholder from "../../../images/farmMapPlaceholder.png"

export default function GfAction(generalFarm, farmDetails){
  return(
    <Page>
      {/* header */}
      <div style = {{height: "18%"}} >
        <Image 
          src = {action}
          style={{position: 'relative',
          display: 'flex',
          paddingBottom: 0,
          marginBottom: 0}} />
      
        <div style={{position: 'absolute'}}>
          <Text style={styles.title}>The Action</Text>
          <Text style={styles.secondSubtitle}>General Farm Management</Text>  
        </div>
      </div>

      {/* first dark square box */}
      <div style ={[styles.dark_square_box,{marginTop: "5%"}]}>
        <div style={{position: 'absolute'}}>
            <Text style={styles.text_dark_square}>
              Map of farm identifying the key environmental aspects of the farm 
            </Text>
          </div>
      </div>

      {/* map */}
      <div style = {styles.map_holder}>
        <Image 
          src = {generalFarm.farmMap || farmMapPlaceholder}
          style={{ height: "100%", weight: "80%"}}/>
      </div>

      {/* dark square box */}
      {generalFarm.mapTitle !== '' && (
      <div style ={styles.dark_square_box}>
        <div style={{position: 'absolute'}}>
          <Text style={styles.text_dark_square}>
            {generalFarm.mapTitle}
          </Text>
        </div>
      </div> 
      )}
      {/* left box */}

      <div style= {{height: 200, marginLeft: "5%", marginRight: "5%", background: "green"}}>
        <View style = {{height: 200, flexDirection: "row"}}>
          <div style={{width: "65%", height: 200}}>
            <Text style={styles.text_leftbox}>
              {generalFarm.mapDescription}
            </Text> 
          </div>

          <div style={{width: "35%", height: 200, backgroundColor: "#EBF4F9"}}> 
            <Text style={styles.text_rightbox}>
              {generalFarm.mapFocus}
            </Text>
          </div>

        </View>
      </div> 

      <Text style={styles.farmName}>{farmDetails.farm_owner} - {farmDetails.supplier_no}</Text>
      <Text style={styles.pageNumber} render={({ pageNumber }) => (
        `${pageNumber}`
      )} fixed />
  </Page>
  )
}